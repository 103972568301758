(function() {
  'use strict';

  function BlueprintController(
    $q,
    $state,
    $stateParams,
    $rootScope,
    Blueprints,
    Auth,
    Security,
    Notify,
    Utils,
    TREE_OPTIONS
  ) {
    var ctrl = this;

    ctrl.hasEditPermission = false;
    Security.hasPermission('blueprints.edit')
      .then(function(has) {
        ctrl.hasEditPermission = has;
      })
      .catch(function(error) {
        if (error.status !== 403) {
          return $q.reject(error);
        }
      })
      .then(function() {
        ctrl.tree = {
          template: {
            id: '_id',
            title: 'name',
            nodes: 'categories'
          },
          options: _.assign(
              angular.copy(TREE_OPTIONS),
            {
              canEdit: ctrl.hasEditPermission,
              inline: true,
              allowSelect: false
            }
          )
        };
      });

    ctrl.blueprint = {};
    Blueprints.blueprintTypes()
      .then(function(blueprintTypes) {
        ctrl.blueprintTypes = blueprintTypes;
      });
    ctrl.eventTypes = [];

    ctrl.blueprintSettings = {
      tree: {
        hideFromTimeline: [
          {
            value: true,
            label: 'No'
          },
          {
            value: false,
            label: 'Yes'
          }
        ],
        aggregateBy: [
          {
            value: 'none',
            label: 'None'
          },
          {
            value: 'countTags',
            label: 'Count tags'
          },
          {
            value: 'coverage',
            label: 'Show coverage'
          }
        ],

        // previously was a checkbox
        taggable: [
          {
            value: false,
            label: 'No one can tag against this blueprint'
          },
          {
            value: true,
            label: 'Everyone can tag against this blueprint'
          },
          {
            value: 'byRelation',
            label: 'Only users who have a relation in which this blueprint is restricted can tag'
          }
        ],

        usage: [
          {
            value: 'events',
            label: 'Event tagging'
          },
          {
            value: 'goal_periods',
            label: 'Goal period tagging'
          }
        ]
      }
    };

    ctrl.blueprint.categories = [];

    ctrl.state = 'view';
    if ($stateParams.id) {
      ctrl.state = 'edit';
      Blueprints.getForEdit($stateParams.id)
        .then(function(data) {
          ctrl.blueprint = data;
          ctrl.title = 'Edit Blueprint: ' + ctrl.blueprint.name;
          Utils.setPageTitle(ctrl.title);
          ctrl.loaded = true;
        });
      Blueprints.getEventTypeUsage($stateParams.id)
        .then(function(data) {
          ctrl.eventTypes = data;
        })
        .catch(function(err) {
          ctrl.error = err;
        });
    } else {
      ctrl.state = 'create';
      ctrl.title = 'New Blueprint';
      if ($stateParams.defaults) {
        ctrl.blueprint = $stateParams.defaults;
        ctrl.state = 'duplicating';
        ctrl.title = 'Duplicating Blueprint: ' + ctrl.blueprint.name;
      }
      Utils.setPageTitle(ctrl.title);

      ctrl.loaded = true;
    }

    ctrl.loadAuditlog = function() {
      Blueprints.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    var getRequiredFieldsError = function() {
      var requiredFields = [
        {
          id: 'name',
          msg: 'The blueprint name field is required!'
        }, {
          id: 'blueprintType',
          msg: 'The blueprint type field is required!'
        }
      ];

      if (_.indexOf(['tree', 'discrete'], ctrl.blueprint.blueprintType) !== -1) {
        requiredFields.push({
          id: 'taggable',
          msg: 'Select whether users will be able to tag their events against this blueprint!'
        });
      }

      return _.chain(requiredFields)
            .filter(function(field) {
              return _.isUndefined(ctrl.blueprint[field.id]);
            })
            .map(function(field) {
              return field.msg;
            })
            .value()
            .join('\n');
    };

    ctrl.duplicate = function() {
      Blueprints.getNewCopy(ctrl.blueprint._id)
        .then(function(newCopy) {
          $state.go('epf.blueprints.new', { defaults: newCopy });
        });
    };

    ctrl.save = function(isValid) {
      var error;
      var alertTitle;
      if (!ctrl.hasEditPermission) {
        alertTitle = 'Sorry!';
        error = 'You don\'t have the permission to perform that action';
      } else if (!isValid) {
        alertTitle = 'The form is not valid!';
        // TO BE REMOVED
        // when we have angular-formly implemented into blueprints
        error = getRequiredFieldsError();
      }

      if (error) {
        Utils.swal({
          title: alertTitle,
          text: error,
          type: 'error'
        });
        return;
      }

      ctrl.formIsSubmitted = true;

      // Make sure this is blueprint and not something else
      ctrl.blueprint.type = 'blueprint';

      // Generate an ID if it doesn't have one
      ctrl.blueprint._id = ctrl.blueprint._id ? ctrl.blueprint._id : Utils.guid();
      ctrl.blueprint.organisation = Auth.currentOrganisation();

      Blueprints.save(ctrl.blueprint)
        .then(function(data) {
          ctrl.blueprint._rev = data._rev;
          ctrl.formIsSaved = true;
          Notify.success('Your blueprint was saved successfully.', 'Success!');
          $rootScope.$broadcast('KZReloadHeader');
          $state.go('epf.blueprints.index');
        })
        .catch(Utils.showError);
    };

    ctrl.addCategory = function() {
      ctrl.blueprint.categories = ctrl.blueprint.categories ? ctrl.blueprint.categories : [];
      ctrl.blueprint.categories.push({
        _id: Utils.guid(),
        name: '',
        description: '',
        categories: []
      });
    };

    ctrl.showDescription = function(scope) {
      var nodeData = scope.$modelValue;

      ctrl.shownDescriptions = ctrl.shownDescriptions ? ctrl.shownDescriptions : {};
      ctrl.shownDescriptions[nodeData._id] = _.has(ctrl.shownDescriptions, nodeData._id) ?
        !ctrl.shownDescriptions[nodeData._id] :
        true;
    };

    ctrl.removeNode = function(scope) {
      scope.remove();
    };

    ctrl.toggleNode = function(scope) {
      scope.toggle();
    };

    ctrl.newSubNode = function(scope) {
      var nodeData = scope.$modelValue;
      nodeData.categories.push({
        _id: Utils.guid(),
        name: '',
        description: '',
        categories: []
      });
    };
    ctrl.isObjectEmpty = function(obj) {
      return _.isEmpty(obj);
    };

    ctrl.onChangeType = function(newType) {
      _.forEach(['restrictable', 'usage'], function(attr) {
        if (!_.isUndefined(ctrl.blueprint[attr])) {
          delete ctrl.blueprint[attr];
        }
      });

      if (_.indexOf(['discrete', 'tree', 'likert'], newType) === -1) {
        delete ctrl.blueprint.categories;
      }
    };
  }

  BlueprintController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    '$rootScope',
    'BlueprintsService',
    'AuthService',
    'SecurityService',
    'NotifyService',
    'UtilsService',
    'TREE_OPTIONS'
  ];

  angular.module('component.blueprints')
    .controller('BlueprintController', BlueprintController);
})();
