import { Component, inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-signin-error',
    templateUrl: './signin-error.component.html',
    imports: [
        MatDialogModule,
        MatButton
    ],
})
export class SigninErrorComponent {

    data = inject(MAT_DIALOG_DATA);

    private dialogRef = inject(MatDialogRef);

    logout() {
        this.dialogRef.close(true);
    }

    onCancel() {
        this.dialogRef.close(false);
    }

}
