import { Component, computed, inject } from '@angular/core';
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import Swal from 'sweetalert2';
import { CommonModule, NgIf } from "@angular/common";
import { CredentialService } from "@fry/system/account/credential/crendential.service";
import {
  ApiCredential,
  CredentialTypes,
  LocalCredential,
  ProxyCredential,
  READABLE_CREDENTIAL_TYPES
} from "@fry/system/account/credential/credential";
import { AuthService } from "@fry/core/auth.service";
import {
  CreateLocalCredentialDialogComponent
} from "./dialogs/create-local-credential-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CreateProxyCredentialDialogComponent
} from "./dialogs/create-proxy-credential-dialog.component";
import {
  UpdateCredentialUsernameDialogComponent
} from "@fry/system/account/credential/dialogs/update-credential-username-dialog.component";
import {
  UpdatePasswordDialogComponent
} from "@fry/system/account/credential/dialogs/update-credential-password-dialog.component";
import { RouteService, upgradeProviders } from '@fry/upgrade/route.service';
import { RouteLinkJsDirective } from '@fry/upgrade/route.link.js.directive';
import { SecurityService } from "@fry/core/security.service";
import { PERMISSION_ID, SYSTEM_ROLE_ID } from "@fry/core/roles.system";
import { OrganisationService } from "@fry/core/organisation.service";
import { setPageTitle } from '@fry/core/utils/seo';
import { isOKResponse, Response } from "@fry/core/api";
import { OidcService } from '@fry/core/oidc.service';
import { MFAMethod } from "@fry/system/account/mfa/mfa";


@Component({
    selector: 'eas-credentials',
    templateUrl: './list-credentials.component.html',
    imports: [
        CommonModule,
        MatTabGroup,
        MatTab,
        RouteLinkJsDirective,
        NgIf,
    ],
    providers: [
        ...upgradeProviders,
    ]
})
export class ListCredentialsComponent {
  private routeService = inject(RouteService);
  private oidc = inject(OidcService);

  public remoteUser = this.routeService.getParam('user');
  public isForRemoteUser = computed(() => this.remoteUser() !== undefined);
  public accountId = computed(() => this.remoteUser());
  private isAdmin: boolean = false;
  private isSuperAdmin: boolean = false;
  private hasManagePermission: boolean = false;

  allowedAccountTypes: CredentialTypes[] = [];
  private enabledMFAs: MFAMethod[] = [];
  loginCredentials: (LocalCredential | ProxyCredential)[] = [];
  apiCredentials: ApiCredential[] = [];
  readableName = READABLE_CREDENTIAL_TYPES;

  newlyCreatedAPICredential: ApiCredential | null;

  protected readonly CredentialTypes = CredentialTypes;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private organisationService: OrganisationService,
    private securityService: SecurityService,
    private credentialService: CredentialService,
  ) {
    this.setPermissions();
  }

  ngOnInit() {
    setPageTitle('Account security');
    this.loadCredentials();
    this.setOrganisationAllowedAccountTypes();
  }

  private async loadCredentials() {
    const response = await this.credentialService.getCredentials(this.accountId())
    if (!isOKResponse(response)) {
      return;
    }

    const credentials = response.data;
    this.loginCredentials = credentials.filter(cred => cred.type !== CredentialTypes.API) as (LocalCredential | ProxyCredential)[];
    this.apiCredentials = credentials.filter(cred => cred.type === CredentialTypes.API) as ApiCredential[];
  }

  private async setOrganisationAllowedAccountTypes() {
    const currentOrganisation = this.organisationService.currentOrganisation();
    if (currentOrganisation) {
      this.allowedAccountTypes = currentOrganisation.allowedAccountTypes;
      this.enabledMFAs = currentOrganisation.enabledMFAs;
    }
  }

  // Permissions
  private async setPermissions() {
    this.isSuperAdmin = await this.securityService.hasRole(SYSTEM_ROLE_ID.SUPER_ADMIN);
    this.isAdmin = await this.securityService.hasRole(SYSTEM_ROLE_ID.ADMIN);

    let permission = String(PERMISSION_ID.CREDENTIAL_MANAGE);
    if (!this.isForRemoteUser()) {
      permission += '.own';
    }
    this.hasManagePermission = await this.securityService.hasPermission(permission);
  }

  canCreateCredential(type: CredentialTypes): boolean {
    if (type == CredentialTypes.PROXY) {
      return this.isAdmin;
    }

    if (type === CredentialTypes.API) {
      return this.isSuperAdmin;
    }

    return this.allowedAccountTypes.includes(type) && this.hasManagePermission;
  }

  canAccessTwoFactorAuth(type: CredentialTypes.LOCAL | CredentialTypes.PROXY) {
    return type === CredentialTypes.LOCAL && this.enabledMFAs.length > 1;
  }

  canUpdateUsername(type: CredentialTypes): boolean {
    return type === CredentialTypes.LOCAL && this.hasManagePermission;
  }

  canUpdateSSOID(type: CredentialTypes): boolean {
    return type === CredentialTypes.PROXY && this.isAdmin;
  }

  canSendPasswordEmail(type: CredentialTypes): boolean {
    return type === CredentialTypes.LOCAL && this.hasManagePermission && this.isForRemoteUser();
  }

  canUpdatePassword(type: CredentialTypes): boolean {
    return type === CredentialTypes.LOCAL && (!this.isForRemoteUser() || this.hasManagePermission);
  }

  canDelete(): boolean {
    if (this.isForRemoteUser()) {
      return this.hasManagePermission;
    } else {
      return this.hasManagePermission && this.loginCredentials.length > 1
    }
  }

  canAccessAPIKeys(): boolean {
    return this.isAdmin;
  }

  // Actions
  async addLoginCredential(type: CredentialTypes) {
    await this.oidc.ensureStepup();

    if (type === CredentialTypes.LOCAL) {
      this.createLocalCredential();
    } else if (type === CredentialTypes.PROXY) {
      this.createProxyCredential();
    }
  }

  private createLocalCredential() {
    const dialogRef = this.dialog.open(CreateLocalCredentialDialogComponent, {
      minWidth: '20vw',
      disableClose: true,
      data: { isForRemoteUser: this.isForRemoteUser() }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const org = this.authService.currentOrganisation();
        if (!org) {
          throw new Error('Organisation not found');
        }
        const localCredential: LocalCredential = {
          type: CredentialTypes.LOCAL,
          organisationId: org,
          username: result.username,
          password: result.password
        }
        const response = await this.credentialService.createCredential(localCredential, this.accountId());
        this.handleResponse(response, 'Local credential created successfully!', 'Failed to create Local credential');
        this.loadCredentials();
      }
    });
  }

  private createProxyCredential() {
    const dialogRef = this.dialog.open(CreateProxyCredentialDialogComponent, {
      minWidth: '20vw',
      disableClose: true,
      data: { isForRemoteUser: this.isForRemoteUser() }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const org = this.authService.currentOrganisation();
        if (!org) {
          throw new Error('Organisation not found');
        }
        const proxyCredential: ProxyCredential = {
          type: CredentialTypes.PROXY,
          organisationId: org,
          username: result.username
        }
        const response = await this.credentialService.createCredential(proxyCredential, this.accountId());
        this.handleResponse(response, 'SSO credential created successfully!', 'Failed to create SSO credential');
        this.loadCredentials();
      }
    });
  }

  async createApiCredential() {
    await this.oidc.ensureStepup();
    const apiCredential: ApiCredential = { type: CredentialTypes.API };
    const response = await this.credentialService.createCredential(apiCredential, this.accountId());
    this.handleResponse(response, 'API credential created successfully!', 'Failed to create API credential');
    this.loadCredentials();
    this.newlyCreatedAPICredential = response.data as ApiCredential;
  }

  copyToClipboard() {
    if (this.newlyCreatedAPICredential) {
      const text = `public_key: ${this.newlyCreatedAPICredential.public_key}\nsecret_key: ${this.newlyCreatedAPICredential.secret_key}`;
      navigator.clipboard.writeText(text).then(() => {
        this.snackBar.open('API credentials copied to clipboard!', 'Close', { duration: 3000 });
      });
    }
  }

  async updateUsername(credential: LocalCredential | ProxyCredential) {
    await this.oidc.ensureStepup();
    const dialogRef = this.dialog.open(UpdateCredentialUsernameDialogComponent, {
      width: '400px',
      disableClose: true,
      data: { currentCredentialUsername: credential.username, credentialType: credential.type }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const currentCredentialUsername = credential.username;
        if (!currentCredentialUsername) {
          throw new Error('Username not found');
        }
        const response = await this.credentialService.updateCredentialUsername(
          credential.type,
          currentCredentialUsername,
          result.newUsername,
          this.accountId()
        );
        this.handleResponse(response, 'Username updated successfully!', 'Failed to update username');
        this.loadCredentials();
      }
    });
  }

  async updatePassword(localCredential: LocalCredential) {
    await this.oidc.ensureStepup();
    const dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      data: { username: localCredential.username }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const { newPassword, passwordConfirmation } = result;
        if (!localCredential.username) {
          throw new Error('Username not defined');
        }
        const response = await this.credentialService.updatePassword(localCredential.username, newPassword, passwordConfirmation, this.accountId());
        this.handleResponse(response, 'Password updated successfully!', 'Failed to update password');
      }
    });
  }

  async deleteCredential(credential: LocalCredential | ProxyCredential | ApiCredential) {
    await this.oidc.ensureStepup();
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this credential? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    });

    if (!confirmation.isConfirmed) {
      return;
    }

    var response;
    if (credential.type === CredentialTypes.API) {
      if (!credential.public_key) {
        throw new Error('Public key not defined');
      }
      response = await this.credentialService.deleteApiCredential(credential.public_key, this.accountId());
    } else {
      response = await this.credentialService.deleteLoginCredential(credential, this.accountId());
    }

    this.handleResponse(response, 'Credential deleted successfully!', 'Failed to delete credential');
    this.newlyCreatedAPICredential = null;
    this.loadCredentials();
  }

  async sendPasswordEmail(localCredential: LocalCredential) {
    await this.oidc.ensureStepup();
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will send an email to this user containing a link for them to follow to reset ' +
        'their password. Would you like to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it!',
      cancelButtonText: 'No, cancel'
    });

    if (!confirmation.isConfirmed) {
      return;
    }
    if (!localCredential.username) {
      throw new Error('Username not defined');
    }
    const response = await this.credentialService.sendPasswordResetEmail(
      this.accountId(),
      localCredential.username
    );

    this.handleResponse(response, 'An email would be sent shortly!', 'Failed to reset the password');
  }

  handleResponse(response: Response<any>, successMessage: string, errorMessage: string) {
    if (response.state !== "ok") {
      this.snackBar.open(`${errorMessage}: ${response.error}. Please try again later.`, 'Close', {
        duration: 3000,
      });
      return;
    }

    this.snackBar.open(successMessage, 'Close', { duration: 3000 });
  }
}
