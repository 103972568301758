import { ChangeDetectionStrategy, Component, computed, Inject, inject, input, signal } from "@angular/core";
import { FeaturesService } from "./features.service";
import { Feature } from "./feature";
import { SecurityService } from "@fry/core/security.service";
import { NgClass } from "@angular/common";

import {
    MatDialog,
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatDialogModule,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import {MatButtonModule} from '@angular/material/button';
import { DomSanitizer } from "@angular/platform-browser";

export interface DialogData {
    feature: Feature;
}


@Component({
    selector: "eas-feature-settings",
    templateUrl: './feature.settings.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        MatButtonModule,
        MatDialogModule,
    ]
})
export class FeatureSettingsComponent {

  feature = input.required<Feature>();

  private featureService = inject(FeaturesService);
  private security = inject(SecurityService);
  public canManageFeatures = signal<boolean>(false);
  public dialog = inject(MatDialog);
  private domSanitizer = inject(DomSanitizer);

  featureScript = computed(() => {
    const feature = this.feature();
    if (feature.embedded_script) {
      return this.domSanitizer.bypassSecurityTrustHtml(feature.embedded_script);
    }
    return null;
  });

  ngOnInit() {
    this.security.hasPermission('features.manage').then(hasRole => this.canManageFeatures.set(hasRole));
  }

  async openDialog() {
    const dialogRef = this.dialog.open(DialogAnimationsExampleDialog,
        {
            data: { feature: this.feature() }
        }
    );

    return dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result === 'enable') {
          this.enableFeature();
      } else if (result === 'disable') {
          this.disableFeature();
      }
    });
  }

  async enableFeature() {
    await this.featureService.enableFeature(this.feature());
  }

  async disableFeature() {
    await this.featureService.disableFeature(this.feature());
  }
}

@Component({
    selector: 'eas-feature-details',
    template: `<h2 mat-dialog-title>{{ data.feature.label }}</h2>
    <mat-dialog-content>
      <p>This feature is currently {{ data.feature.status.enabled ? 'enabled' : 'disabled' }}.</p>
      @if (data.feature.status.enabled) {
        <p [innerHtml]="data.feature.description_disable"></p>
      }
      @else {
        <p [innerHtml]="data.feature.description_enable"></p>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      @if (data.feature.status.enabled) {
        <button mat-button mat-dialog-close="disable">Disable</button>
      }
      @else {
        <button mat-button mat-dialog-close="enable">Enable</button>
      }
      <button mat-button mat-dialog-close="close" cdkFocusInitial>Close</button>
    </mat-dialog-actions>`,
    imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogModule]
})
  export class DialogAnimationsExampleDialog {

    dialogRef = inject(MatDialogRef);
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  }