(function() {
  'use strict';

  function dateTimePicker(Form, moment) {
    return {
      scope: {
        model: '=',
        disabled: '=',
        required: '='
      },
      templateUrl: 'app/blocks/utils/datetimepicker.html',
      link: function(scope) {
        function transToView(date) {
          if (date === undefined) {
            return {};
          }
          console.log('DateTimePicker: Loading', date);
          var dt = moment(date);
          if (!dt.isValid()) {
            return {};
          }
          return {
            date: dt.toDate(),
            hours: dt.format('HH'),
            minutes: dt.format('mm')
          };
        }

        function transFromView(dt) {
          if (_.isUndefined(dt) || _.isEmpty(dt)) {
            return;
          }

          var conditions = [
            !_.isUndefined(dt.date),
            !_.isUndefined(dt.hours),
            !_.isUndefined(dt.minutes)
          ];
          if (!_.every(conditions)) {
            return;
          }

          // Construct the date in local time (browser timezone)
          var enteredDate = moment(dt.date);
          var date = moment({
            year: enteredDate.year(),
            month: enteredDate.month(),
            day: enteredDate.date(),
            hour: dt.hours,
            minute: dt.minutes
          });
          return date.toISOString(); // Converts to UTC automatically
        }

        if (scope.model) {
          scope.dt = transToView(scope.model);
        }

        function changeDateTime() {
          var dt = transFromView(scope.dt);
          scope.model = dt;
        }

        function loadTimeForm(required) {
          scope.timeForm = new Form(
            [
              {
                id: 'date',
                type: 'date',
                label: 'Date',
                required: required
              },
              {
                id: 'hours',
                type: 'discrete',
                label: 'Hours',
                required: required,
                options: _.map(_.range(0, 24), function(hours) {
                  if (hours < 10) {
                    hours = '0' + hours;
                  }
                  hours = hours.toString();
                  return { _id: hours, key: hours, name: hours };
                })
              },
              {
                id: 'minutes',
                type: 'discrete',
                label: 'Minutes',
                required: required,
                options: _.map(_.range(0, 60), function(minutes) {
                  if (minutes < 10) {
                    minutes = '0' + minutes;
                  }
                  minutes = minutes.toString();
                  return { _id: minutes, key: minutes, name: minutes };
                })
              }
            ]
          );
        }

        loadTimeForm(scope.required);

        scope.$watchCollection('dt', function() {
          changeDateTime();
          if (!scope.required && !_.isUndefined(scope.dt) && !_.isEmpty(scope.dt)) {
            loadTimeForm(true);
          }
        });

        scope.reset = function() {
          scope.model = undefined;
          scope.dt = {};
          loadTimeForm(scope.required);
        };
      }
    };
  }

  dateTimePicker.$inject = ['FormsService', 'moment'];

  angular.module('blocks.utils')
    .directive('kzDateTimePicker', dateTimePicker);
})();
