<h2 mat-dialog-title>Re-authentication</h2>
<mat-dialog-content>
    <p>
        There was an error signing in. Please log out and log back in.
    </p>

    <p>Access denied reason:</p>
    <pre>{{ data.error.message }}</pre>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" type="button" (click)="logout()">Log out</button>
</mat-dialog-actions>
