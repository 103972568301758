(function() {
  'use strict';

  function MenuSettingsLinksDirective() {
    function MenuSettingsLinksController($state, $q, Security, Features, Organisation) {
      var ctrl = this;

      // Start all as invisible
      ctrl.showMainLink = false;
      ctrl.canManageLabsSettings = false;
      ctrl.canManageOrganisationSettings = false;
      ctrl.canManageProtectedSettings = false;

      var manageLabsSettingsPromise = Security.hasPermission('features.manage')
        .then(function() {
          return Organisation.currentOrganisation()?.enabledLabs;
        }).catch(function() {
          return false;
        });

      var manageOrganisationSettingsPromise = Security.hasPermission('features.manage')
        .then(function() {
          return Features.freeFeatures().length > 0;
        }).catch(function() {
          return false;
        });

      var manageProtectedSettingsPermission = Security.hasPermission('features.manage')
        .then(function() {
          return true;
        }).catch(function() {
          return false;
        });

      $q.all([
        manageLabsSettingsPromise,
        manageOrganisationSettingsPromise,
        manageProtectedSettingsPermission
      ])
        .then(function(results) {
          ctrl.canManageLabsSettings = results[0]; // manageLabSettingsPromise
          ctrl.canManageOrganisationSettings = results[1]; // manageOrganisationSettingsPromise
          ctrl.canManageProtectedSettings = results[2]; // manageProtectedSettingsPromise

          // If any is visible, show the dropdown menu trigger
          ctrl.showMainLink = ctrl.canManageLabsSettings ||
            ctrl.canManageOrganisationSettings ||
            ctrl.canManageProtectedSettings;
        });

      ctrl.hasActiveLinks = function() {
        var states = ['epf.settings.labs', 'epf.settings.organisation', 'epf.settings.protected'];
        return states.some(function(s) {
          return $state.includes(s);
        });
      };
    }

    MenuSettingsLinksController.$inject = [
      '$state',
      '$q',
      'SecurityService',
      'FeaturesService',
      'OrganisationService'
    ];

    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/kz-menu-settings-links.html',
      replace: true,
      controller: MenuSettingsLinksController,
      controllerAs: 'menuSettingsCtrl'
    };
  }

  angular.module('kzHeaderCard')
    .directive('kzMenuSettingsLinks', MenuSettingsLinksDirective);
})();
