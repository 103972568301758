
(function() {
  'use strict';

  function ReportFieldDirective($q, $log, $timeout, Reports, Report, Utils, LocalizationService) {
    return {
      scope: {
        reportEx: '=report',
        reportDoc: '=',
        reportId: '@',
        model: '=',
        defaultModel: '=',
        extra: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-field.html',
      replace: true,
      link: function($scope) {
        $scope.options = $scope.options || {};
        $scope.size = $scope.options?.size || 500;
        $scope.dateFormat = LocalizationService.getDateTimeFormat('datetime');
        function loadReport() {
          var promise;
          if ($scope.reportEx !== undefined) {
            $scope.baseReport = $scope.reportEx;
            promise = $q.when();
          } else if ($scope.reportDoc !== undefined) {
            $scope.baseReport = new Report($scope.reportDoc);
            promise = $q.when();
          } else {
            promise = Reports.find($scope.reportId, true)
              .then(function(doc) {
                $scope.baseReport = new Report(doc);
              })
              .catch(function(err) {
                $log.warn('Report not found', err);
              });
          }

          return promise;
        }

        loadReport()
          .then(function() {
            // This is an ugly hack - injecting options in the middle of the
            // process makes it open to errors
            $scope.baseReport.options = $scope.options;
            $scope.report = $scope.baseReport.getSingleOrMultiReportByDoc();
            return $scope.report.getFields();
          })
          .then(function(fields) {
            $scope.fields = fields;

            $scope.defaultModelWithUser = $scope.report.modelWithUser(
              $scope.defaultModel, $scope.extra.user
            );
            var dflt = {
              report: $scope.baseReport.doc._id,
              reportData: $scope.defaultModelWithUser
            };

            $scope.model = $scope.model || dflt;
            $scope.loaded = true;
          });

        $scope.generate = function() {
          $scope.model.generatedAt = Utils.now();
        };

        $scope.regenerate = function() {
          $scope.edit();
          $timeout($scope.generate, 10);
        };

        $scope.$on('kzReportFormValidity', function(_evt, args) {
          $scope.modelValid = args.validity;
        });

        $scope.edit = function() {
          $scope.model.generatedAt = undefined;
          $scope.model.reportResult = undefined;
        };

        $scope.storeResult = function(rawResult) {
          var result;
          var reportId;
          if (rawResult && rawResult.report) {
            result = rawResult.data;
            reportId = rawResult.report;
          } else {
            result = rawResult;
          }

          var reportResult = {};

          $log.debug('Storing result');
          if (result && result.table && result.table.total > $scope.size) {
            $log.warn('Cannot store more than ' + $scope.size + ' rows');
            $scope.status = {
              text: 'This report contains too many results to be stored inside the ' +
                    'event. Each time this event is viewed the report will be generated afresh.',
              klass: 'warning'
            };
            reportResult = {
              error: { status: 413, message: 'Report data too large' }
            };
          } else {
            $scope.status = {
              text: 'This report will be stored inside this event with the results as at ' +
                      'the time of submission.',
              klass: 'info'
            };
            reportResult = result;
          }

          if (reportId) {
            $scope.model.reportResult = $scope.model.reportResult || {};
            $scope.model.reportResult[reportId] = reportResult;
          } else {
            $scope.model.reportResult = reportResult;
          }
        };
      }
    };
  }

  ReportFieldDirective.$inject = ['$q', '$log', '$timeout', 'ReportTemplatesService',
    'ReportFactory', 'UtilsService', 'LocalizationService'];

  angular.module('component.reports')
    .directive('kzReportField', ReportFieldDirective);
})();
