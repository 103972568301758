import { Injectable, computed, inject, signal } from '@angular/core';
import { AccessTokenCallback, User, UserLoadedCallback, UserManager } from 'oidc-client-ts';
import { BroadcastService } from './broadcast.service';
import { NetworkService } from './network.service';
import { MatDialog } from '@angular/material/dialog';
import { StepupComponent } from './stepup.component';
import { SigninPopupComponent } from './signin-popup.component';
import { SigninErrorComponent } from './signin-error.component';

export interface OidcOptions {
    scope?: string;
    resource?: string;
    organisation?: string;
    extraQueryParams?: {
        kz_org?: string;
        kz_fast_redirect?: string;
    }
}

export interface EasUser {
    username: string;
    usernameHeader: string;
    login_type: 'oidc';
    masqueradeBy?: string;
    originalProfile: {
        username: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        fullName?: string;
    },
    user: User | null;

}

const anonymousUser: EasUser = {
    username: 'anonymous',
    usernameHeader: 'anonymous',
    login_type: 'oidc',
    originalProfile: {
        username: 'anonymous',
        firstName: 'Anonymous',
        lastName: '',
        email: '',
        fullName: 'Anonymous',
    },
    user: null,
};


export interface ApiInfo {
    token: string;
    organisation: string | null;
    username: string;
}

@Injectable({
    providedIn: 'root'
})
export class OidcService {

    private dialog = inject(MatDialog);

    readonly $currentOrganisation = signal<string | null>(null);
    readonly currentUserSignal = signal<User | null>(null);
    private readonly $loggingOut = signal<boolean>(false);
    readonly currentUser = computed(() => {
        const user = this.currentUserSignal();
        if (!user) {
            return anonymousUser;
        }
        console.log("OIDC ok", user.expires_in, user.scopes, Boolean(user.refresh_token) ? 'refresh_token' : 'no refresh_token');
        return this.userToEasUser(user);
    });

    readonly apiInfo = computed(() => this.getApiInfo(this.currentUser()));

    private _organisation: string | null = null;

    private _renewTokenPromise: Promise<User> | null = null;
    private userLoadedCallback: UserLoadedCallback = (user: User) => {
        if (user) {
            console.log('OIDC: User loaded', user.expires_in, user.scopes);
            this.currentUserSignal.set(user);
        } else {
            console.log('OIDC: User loaded - no user');
        }
    }
    private accessTokenExpiredCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expired');
    }
    private accessTokenExpiringCallback: AccessTokenCallback = () => {
        console.log('OIDC: Token expiring');
        this.renewToken();
    }

    private callbacks = {
        UserLoaded: this.userLoadedCallback,
        AccessTokenExpired: this.accessTokenExpiredCallback,
        AccessTokenExpiring: this.accessTokenExpiringCallback,
    }

    userManager: UserManager | null = null;
    private broadcast = inject(BroadcastService);
    private network = inject(NetworkService);
    private channel: BroadcastChannel | undefined;

    constructor() {
        const org = localStorage.getItem('currentOrganisation');
        this.setOrganisation(org);
        this.setupBroadcastLogout();
    }

    private setupBroadcastLogout() {
        try {
            this.channel = new BroadcastChannel('eas-logout');

            this.channel.addEventListener('message', (event) => {
                if (event.data?.type === 'logout') {
                    if (this.userManager) {
                        this.$loggingOut.set(true);
                        console.log('OIDC: Removing user');
                        const currentUsername = this.currentUserSignal()?.profile.sub;
                        this.userManager.removeUser();
                        this.currentUserSignal.set(null);
                        const options = this.getLoginSettings({});
                        this.signInPopup(options, currentUsername);
                    }
                }
            });
        } catch (err) {
            console.log('Could not setup broadcast logout', err);
        }
    }

    public async init(initOptions: {relogin: boolean, noLastUrl?: boolean}): Promise<User | null> {
        console.log('OIDC: init');
        this.userManager = this.createManager();
        console.log('OIDC: created');

        Object.entries(this.callbacks).forEach(([name, callback]) => {
            if (this.userManager) {
                this.userManager.events[`add${name}`](callback);
            }
        });

        try {
            const user = await this.userManager.getUser();
            if (!user) {
                // return user;
                if (initOptions.relogin) {
                    this.login({ noLastUrl: initOptions.noLastUrl });
                    throw new Error('User not logged in');
                }
                return null;
            } else if (user.expired) {
                return this.renewToken({ silent: true }).catch(err => {
                    console.log(err);
                    if (initOptions.relogin) {
                        this.login();
                        throw new Error('User not logged in');
                    }
                    return null;
                });
            } else {
                const userToStore = { ...user, access_token: null, refresh_token: null };
                localStorage.setItem('oidcUser', JSON.stringify(userToStore));
                this.currentUserSignal.set(user);
                return user;
            }
        } catch (err) {
            if (this.network.isOffline()) {
                console.log('OIDC: Offline');
                const userJSON = localStorage.getItem('oidcUser');
                if (userJSON) {
                    const user = JSON.parse(userJSON);
                    this.currentUserSignal.set(user);
                    return user;
                }
                return null;
            }
            console.log("OIDC: init error", err);
            return null;
        };
    }

    public async resetManager(): Promise<User | null> {
        if (!this.userManager) {
            return this.init({ relogin: true });
        }
        Object.entries(this.callbacks).forEach(([name, callback]) => {
            if (this.userManager) {
                this.userManager.events[`remove${name}`](callback);
            }
        });
        return this.userManager.removeUser().then(() => {
            this.userManager = null;
            return this.init({ relogin: true });
        });
    }

    private getLoginSettings(options: {username?: string | null, scope?: string}): OidcOptions {
        const scopes = ['openid', 'profile', 'email'];
        let masquerade: string | null;
        if (options.username === null) {
            localStorage.removeItem('eas:lastMasquerade');
            masquerade = null;
        } else if (options.username) {
            localStorage.setItem('eas:lastMasquerade', options.username);
            masquerade = options.username;
        } else {
            masquerade = localStorage.getItem('eas:lastMasquerade');
        }

        let resource: string;
        if (masquerade) {
            scopes.push('lase');
            scopes.push(`lase:${masquerade}`);
            resource = `https://risr.global/resources/api+lase:${masquerade}`;
        } else if (options.scope) {
            scopes.push(options.scope);
            resource = `https://risr.global/resources/api+${options.scope}`;
        } else {
            resource = 'https://risr.global/resources/api';
        }

        const extraQueryParams: {
            kz_org?: string;
            kz_fast_redirect?: string;
        } = {};
        if (this.organisation) {
            extraQueryParams.kz_org = this.organisation;
        }
        const kzFast = sessionStorage.getItem('fast_redirect');
        if (kzFast) {
            sessionStorage.removeItem('fast_redirect');
            extraQueryParams.kz_fast_redirect = kzFast;
        }

        const oidcOptions: OidcOptions = {
            scope: scopes.join(' '),
            resource,
            extraQueryParams
        };
        console.log('OIDC: getLoginSettings', oidcOptions);
        return oidcOptions;
    }

    createManager(): UserManager {
        var serverSettings = window['kzConfig'] ? window['kzConfig'].oidc : {};

        var defaultSettings = {
            automaticSilentRenew: false,
            revokeAccessTokenOnSignout: true,
            accessTokenExpiringNotificationTime: 60,
            loadUserInfo: false
        };

        const settings = {
            ...defaultSettings,
            ...serverSettings,
            scope: ''
        };

        settings.extraQueryParams = {};
        if (this.organisation) {
            settings.extraQueryParams.kz_org = this.organisation;
        }
        const kzFast = sessionStorage.getItem('fast_redirect');
        if (kzFast) {
            sessionStorage.removeItem('fast_redirect');
            settings.extraQueryParams.kz_fast_redirect = kzFast;
        }

        console.log('OIDC', settings);
        return new UserManager(settings);
    }

    private userToEasUser(user: User | null): EasUser {
        console.log('OIDC: userToEasUser');
        if (!user) {
            return anonymousUser;
        }

        const laseMatch = user.scope?.match(/lase:([a-zA-Z0-9\-_]+)/);
        let username = user.profile.sub;
        let usernameHeader = user.profile.sub;
        let masqueradeBy: string | undefined;
        if (laseMatch) {
            username = laseMatch[1];
            usernameHeader = `${username}:lase:${user.profile.sub}`
            masqueradeBy = user.profile.sub;
        }
        return {
            username: username,
            login_type: 'oidc',
            usernameHeader: usernameHeader,
            masqueradeBy: masqueradeBy,
            originalProfile: {
                username: user.profile.sub,
                firstName: (user.profile.firstName || '') as string,
                lastName: (user.profile.lastName || '') as string,
                email: user.profile.email,
                fullName: ((user.profile.firstName || '') +
                ' ' + (user.profile.lastName || '')).trim() || user.profile.email
            },
            user: user,
        };
    }


    public async easUser(): Promise<EasUser | null> {
        const easUser = this.currentUser();
        if (easUser && easUser.user && !easUser.user.expired) {
            return easUser;
        }

        return this.user().then((user: User | null) => {
            return this.userToEasUser(user);
        });
    }

    public async user(): Promise<User | null> {
        console.log("OIDC: Requesting user")
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        return this.userManager.getUser().then((user: User | null) => {
            if (user && !user.expired) {
                return user;
            } else if (user) {
                console.log('OIDC: Renew when getting user');
                return this.renewToken({ silent: true }).then((user: User) => {
                    return user;
                }).catch(err => {
                    console.log(err);
                    return null;
                });
            } else {
                return null;
            }
        });
    }

    public login(loginOptions?: { noLastUrl?: boolean }): Promise<void> {
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        const options = this.getLoginSettings({});
        if (loginOptions && loginOptions.noLastUrl) {
            sessionStorage.removeItem('lastUrl');
        } else {
            var href = location.pathname + location.search + location.hash;
            sessionStorage.setItem('lastUrl', href);
        }
        return this.userManager.signinRedirect(options);
    }

    public loginPopup(): Promise<User> {
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        const options = this.getLoginSettings({});
        return this.userManager.signinPopup(options);
    }

    public async renewToken(opts?: { silent: boolean }): Promise<User> {
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        if (this.$loggingOut()) {
            return Promise.reject('User is being logged out');
        }
        const loginOptions = opts || { silent: false};

        const options = this.getLoginSettings({});
        const currentUsername = this.currentUserSignal()?.profile.sub;
        console.log("OIDC: Requesting renewToken");
        if (this._renewTokenPromise === null) {
            console.log("OIDC: renewToken");
            this._renewTokenPromise = this.userManager.signinSilent(options).then(user => {
                if (!user || user.expired) {
                    throw new Error('Token expired');
                }
                console.log("OIDC: renewToken ok");
                this._renewTokenPromise = null;
                return user;
            }).catch(err => {
                if (loginOptions.silent) {
                    return Promise.reject(err);
                }
                console.log('OIDC: renewToken err - trying popup', err);
                return this.signInPopup(options).then(user => {
                    console.log("OIDC: renewToken via popup ok");
                    this._renewTokenPromise = null;
                    return user;
                }).catch(err => {
                    console.log(err);
                    if (err && err.message === 'Attempted to navigate on a disposed window' &&
                        this.userManager
                    ) {
                        var href = location.pathname + location.search + location.hash;
                        sessionStorage.setItem('lastUrl', href);
                        this.userManager.signinRedirect(options);
                    }
                    return Promise.reject(err);
                });
            }).then(user => {
                if (currentUsername && user.profile.sub !== currentUsername) {
                    console.log('OIDC: User mismatch - reloading');
                    location.reload();
                    return Promise.reject("Username mismatch");
                }
                return user;
            }).catch(err => {
                console.log("OIDC: renewToken err", err);
                this._renewTokenPromise = null;
                throw err;
            });
        }
        return this._renewTokenPromise;
    }

    private async signInPopup(options: OidcOptions, currentUsername?: string): Promise<User> {
        const res = await this.dialog.open(SigninPopupComponent, {
            disableClose: true,
            hasBackdrop: true,
        }).afterClosed().toPromise();
        console.log("OIDC: stepUpPopup", res);
        if (res && this.userManager) {
            this.$loggingOut.set(false);
            const user = await this.userManager.signinPopup(options);
            if (currentUsername && user.profile.sub !== currentUsername) {
                console.log('OIDC: User mismatch - reloading');
                location.reload();
                return Promise.reject("Username mismatch");
            }
            return user;
        }
        return Promise.reject({ status: 401, message: 'Sign-in required', code: 'sign-in' });
    }

    public logout(): Promise<void> {
        this.$loggingOut.set(true);
        localStorage.removeItem('eas:lastMasquerade');
        if (this.channel) {
            this.channel.postMessage({ type: 'logout' });
        }
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        return this.userManager.signoutRedirect();
    }

    public loginAs(username: string | null): Promise<void> {
        const options = this.getLoginSettings({username});
        sessionStorage.removeItem('lastUrl');
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        return this.userManager.signinRedirect(options);
    }

    public async stepUp(): Promise<void> {
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }
        const currentUsername = this.currentUserSignal()?.profile.sub;
        const options = this.getLoginSettings({ scope: 'stepup' });
        let user: User | null;
        try {
            user = await this.userManager.signinSilent(options);
            if (!user || !user.scope?.includes('stepup')) {
                throw new Error('Step up authentication required');
            }
        } catch (err) {
            console.log("OIDC: stepUp err - trying popup", err);
            try {
                user = await this.stepUpPopup(options);
                if (!user || !user.scope?.includes('stepup')) {
                    throw new Error('Step up authentication required');
                }
            } catch (err) {
                if (err && err.message === 'Attempted to navigate on a disposed window') {
                    var href = location.pathname + location.search + location.hash;
                    sessionStorage.setItem('lastUrl', href);
                    this.userManager.signinRedirect(options);
                }
                if (err && err.error === 'access_denied') {
                    const res = await this.dialog.open(SigninErrorComponent, {
                        data: { error: err }
                    }).afterClosed().toPromise();
                    if (res) {
                        this.logout();
                    }
                }
                throw new Error(err);
            }
        }
        if (user && currentUsername !== user.profile.sub) {
            console.log('OIDC: User mismatch - reloading');
            location.reload();
            throw new Error('Username mismatch');
        }
    }

    private async stepUpPopup(options: OidcOptions): Promise<User | null> {
        const res = await this.dialog.open(StepupComponent).afterClosed().toPromise();
        console.log("OIDC: stepUpPopup", res);
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }

        let user: User | null = null;
        if (res) {
            try {
                user = await this.userManager.signinPopup(options);
            } catch (err) {
                console.log("OIDC: stepUpPopup err", err);
                throw err;
            }
        }
        return user;
    }

    public async ensureStepup(): Promise<void> {
        let user = this.currentUserSignal();
        if (user && user.scope?.includes('stepup')) {
            return Promise.resolve();
        }

        await this.stepUp();

        user = this.currentUserSignal();
        if (user && user.scope?.includes('stepup')) {
            return Promise.resolve();
        }

        return Promise.reject({ status: 401, message: 'Step up authentication required', code: 'step-up' });
    }

    public logoutAs() {
        return this.loginAs(null);
    };

    public async signinCallback(): Promise<User> {
        const manager = this.createManager();
        return await manager.signinRedirectCallback();
    }

    public async signinPopupCallback(): Promise<void> {
        const manager = this.createManager();
        return await manager.signinPopupCallback();
    }

    public async signinSilentCallback(): Promise<void> {
        const manager = this.createManager();
        return await manager.signinSilentCallback();
    }

    public async isLoggedIn(): Promise<boolean> {
        if (this.network.isOffline()) {
            return this.currentUserSignal() !== null;
        }
        if (!this.userManager) {
            return Promise.reject('User manager not initialized');
        }

        return this.userManager.getUser().then(user => {
            return user !== null && !user.expired;
        });
    }

    public get organisation(): string | null {
        if (this._organisation === undefined) {
            let org;
            try {
                org = localStorage.getItem('currentOrganisation');
            } catch (err) {
                console.log('Could not get organisation from local storage', err);
                org = null;
            }

            this._organisation = org;
        }

        return this._organisation;
    }

    public setOrganisation(organisation: string | null): void {
        // FIXME - update Sentry
        this._organisation = organisation;
        try {
            if (organisation) {
                localStorage.setItem('currentOrganisation', organisation);
            } else {
                localStorage.removeItem('currentOrganisation');
            }
        } catch (err) {
            console.log(err);
        }
        this.$currentOrganisation.set(organisation);
        this.broadcast.broadcast('OrganisationSettingsChanged');
        this.broadcast.broadcast('KZClearCache');
    }

    public unsetOrganisation(): void {
        this.setOrganisation(null);
    }

    private getApiInfo(easUser: EasUser): ApiInfo {
        return {
            token: `Bearer ${easUser.user?.access_token}`,
            organisation: this.$currentOrganisation(),
            username: easUser.usernameHeader,
        };
    }

    public ensureLoggedIn(): Promise<void> {
        return this.easUser().then((user: EasUser | null) => {
            if (user === null) {
                throw new Error('User not logged in');
            }
        });
    }

    public getApiHeaders(): Promise<ApiInfo> {
        return this.easUser().then((easUser: EasUser | null) => {
            if (easUser) {
                return this.getApiInfo(easUser);
            }
            throw new Error('User not logged in');
        });
    }
}