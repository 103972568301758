export enum MFAMethodsEnum {
  EMAIL_OTP = 'email_otp',
  TOTP = 'totp'
}

export const READABLE_MFA_METHODS = {
  [MFAMethodsEnum.EMAIL_OTP]: 'Email',
  [MFAMethodsEnum.TOTP]: 'Authenticator'
};

export interface MFAMethod {
  id: MFAMethodsEnum;
  name: string;
  secure: boolean;
}

export const orderedMFAMethods: MFAMethod[] = [
  { id: MFAMethodsEnum.TOTP, name: 'Authenticator', secure: true },
  { id: MFAMethodsEnum.EMAIL_OTP, name: 'Email', secure: false },
];

export interface MFA {
  id: string;                // Unique ID for the MFA
  type: MFAMethodsEnum;      // Type of MFA
  createdAt: string;         // Creation timestamp
}
