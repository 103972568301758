<h2 mat-dialog-title>{{dialogTitle}}</h2>
<form [formGroup]="editUsernameForm">
  <mat-dialog-content>
    <mat-form-field *ngIf="orgHasintegration" appearance="outline" class="w-full">
      <mat-label>Would you like to also edit this account in your CRM?</mat-label>
      <input type="checkbox" matInput formControlName="useIntegration" required>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ label }}</mat-label>
      <input matInput formControlName="newUsername" required autocomplete="username" />
      <mat-error *ngIf="editUsernameForm.get('newUsername')?.hasError('required')">Username is required</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <ul class="list-inline list-unstyled">
      <li>
        <button class="btn btn-primary" type="submit" [disabled]="editUsernameForm.invalid" (click)="onSubmit()">Change</button>
      </li>
      <li>
        <button class="btn btn-warning btn-outline" (click)="onCancel()">Cancel</button>
      </li>
    </ul>
  </mat-dialog-actions>
</form>
