<h2 mat-dialog-title>Setup new two-factor authentication method</h2>
<mat-dialog-content>
  <ng-container [ngSwitch]="currentStep">
    <!-- Step 1: Method Selection -->
    <div *ngSwitchCase="'selectMethod'">
      <p class="">Choose an authentication method:</p>
      <ul class="list-unstyled">
        <li *ngFor="let method of availableMethods">
          <button class="btn btn-outline btn-lg btn-block" [class]="{'btn-primary': method.secure, 'btn-warning': !method.secure}" (click)="selectMethod(method.id)">
            {{ method.name }}
            @if (method.secure) { <span class="text-sm">(Recommended)</span> }
          </button>
        </li>
      </ul>
    </div>

    <!-- Step 2: Verification -->
    <div *ngSwitchCase="'completeRegistration'">
      <ng-container [ngSwitch]="selectedMethod">

        <!-- Email OTP -->
        <div *ngSwitchCase="MFAMethodsEnum.EMAIL_OTP">
          <p class="text-lg"><strong>Configure email as your two-factor authentication method</strong></p>
          <form [formGroup]="otpForm">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Enter code from your email</mat-label>
              <input matInput formControlName="code" required />
            </mat-form-field>
          </form>
          <p>{{ errorMessage }}</p>
        </div>

        <!-- TOTP -->
        <div *ngSwitchCase="MFAMethodsEnum.TOTP">
          <p class="text-lg"><strong>Configure your Authenticator App</strong></p>
          <div *ngIf="qrCodeDataUrl">
            <p>Scan the QR code with your authenticator app:</p>
            <img [src]="qrCodeDataUrl" alt="TOTP QR Code" />
          </div>
          <p *ngIf="secretKey" class="box box-info flex justify-between items-center">
            <span>{{ secretKey }}</span>
            <button class="btn btn-sm btn-info" type="button" (click)="copyToClipboard()">Copy</button>
          </p>

          <form [formGroup]="totpForm">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Enter code from authenticator app</mat-label>
              <input matInput formControlName="code" required />
            </mat-form-field>
          </form>
          <p *ngIf="errorMessage.length > 0" role="status" class="box box-danger">{{ errorMessage }}</p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</mat-dialog-content>

<!-- Footer Actions -->
<mat-dialog-actions align="end">
  <ul class="list-inline list-unstyled">
    <li><button class="btn btn-info btn-outline" *ngIf="currentStep === 'completeRegistration' && !isPreselectedMethod" (click)="goBack()">Back</button></li>
    <li><button class="btn btn-warning btn-outline" mat-dialog-close (click)="closeDialog()">Cancel</button></li>
    <li><button
      class="btn btn-primary"
      [disabled]="isCompleteRegistrationDisabled()"
      *ngIf="currentStep === 'completeRegistration'"
      (click)="completeRegistration()"
    >
      Verify and complete registration
    </button></li>
  </ul>
</mat-dialog-actions>
