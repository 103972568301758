(function() {
  'use strict';

  function ColumnsMappingController($scope, Form, ALL_TYPES) {
    var ctrl = this;

    ctrl.forms = {};
    $scope.formsModels = {};
    ctrl.form_types = ALL_TYPES;
    ctrl.isLocked = {};
    $scope.entryUniquenessFieldDisabled = {};
    $scope.entryUniquenessFieldShown = {};

    function constructForm(fieldDefinition) {
      if (!fieldDefinition) {
        return;
      }

      var options = null;
      if (fieldDefinition.options) {
        options = Object.entries(fieldDefinition.options).map(([key, value]) => ({
          _id: value,
          key: key,
          name: key
        }));
      }
      var field = {
        id: fieldDefinition._id,
        type: fieldDefinition.type,
        kzType: fieldDefinition.type,
        options: options,
        required: true,
        disabled: ctrl.isLocked[fieldDefinition._id]
      };
      var formId = fieldDefinition._id;
      ctrl.forms[formId] = new Form([field]);
    }

    function countMappedFields() {
      ctrl.autoMapNumber = _.keys($scope.internalMapping).length;
      ctrl.totalColumnsInFile = ctrl.fileColumns.length;
    }

    ctrl.autoMapAll = function() {
      var internalMapping = {};
      _.forEach(ctrl.fileColumns, function(kzFieldName) {
        var matchingKzField = _.find(ctrl.kzFields, function(kzField) {
          return kzField.name === kzFieldName;
        });

        if (!_.isUndefined(matchingKzField)) {
          internalMapping[matchingKzField._id] = {
            fieldId: matchingKzField._id,
            type: 'column',
            value: kzFieldName
          };
        }
      });
      $scope.internalMapping = internalMapping;

      countMappedFields();
    };

    ctrl.setColumn = function(_id) {
      $scope.formsModels[_id] = {};
      $scope.internalMapping[_id] = {
        fieldId: _id,
        type: 'column',
        value: undefined
      };
    };

    ctrl.setConstant = function(_id, fieldDefinition) {
      $scope.internalMapping[_id] = {
        fieldId: _id,
        type: 'constant',
        value: undefined
      };
      constructForm(fieldDefinition);
    };

    ctrl.onChange = function(kzId) {
      $scope.internalMapping[kzId].type = 'column';
      $scope.internalMapping[kzId].fieldId = kzId;
    };

    function initAvailableMappingOptions() {
      ctrl.availableMappingOptions = [{ id: undefined, name: '- None -' }];
      _.forEach(ctrl.fileColumns, function(fileColumn) {
        ctrl.availableMappingOptions.push({ id: fileColumn, name: fileColumn });
      });
    }

    function handleEntryUniquenessChanges(mapping, kzFields, entryUniquenessInit) {
      $scope.entryUniquenessSelectedFields = {};

      function handleDataFields(eventKzFields) {
        var idempotencyKeyFieldId = 'idempotencyKey';
        var idempotencyKeyMapped = mapping[idempotencyKeyFieldId]
          && mapping[idempotencyKeyFieldId].value;

        var entryUniquenessSelectedFields = {};
        if (idempotencyKeyMapped) {
          // If idempotency key is mapped, select it and disable all fields
          eventKzFields.forEach(field => {
            if (field._id === idempotencyKeyFieldId) {
              entryUniquenessSelectedFields[field._id] = true;
            } else {
              entryUniquenessSelectedFields[field._id] = false;
            }
          });
        } else {
          // If idempotency key is not mapped, unselect all and enable all fields
          eventKzFields.forEach(field => {
            // entryUniquenessSelectedFields[field._id] = false;
            if (mapping[field._id] && mapping[field._id].value) {
              $scope.entryUniquenessFieldDisabled[field._id] = false;
            } else {
              $scope.entryUniquenessFieldDisabled[field._id] = true;
            }

            if (ctrl.isLocked[field._id]) {
              $scope.entryUniquenessFieldDisabled[field._id] = true;
            }
          });

          if (entryUniquenessInit) {
            entryUniquenessInit.forEach(function(fieldId) {
              entryUniquenessSelectedFields[fieldId] = true;
            });
          } else {
            // Check startDate and endDate or email if they are mapped
            var emailFieldId = 'email';
            if (mapping[emailFieldId] && mapping[emailFieldId].value) {
              entryUniquenessSelectedFields[emailFieldId] = true;
            }

            var startDateFieldId = 'startDate';
            if (mapping[startDateFieldId] && mapping[startDateFieldId].value) {
              entryUniquenessSelectedFields[startDateFieldId] = true;
            }

            var endDateFieldId = 'endDate';
            if (mapping[endDateFieldId] && mapping[endDateFieldId].value) {
              entryUniquenessSelectedFields[endDateFieldId] = true;
            }
          }
        }

        $scope.entryUniquenessSelectedFields = entryUniquenessSelectedFields;
      }

      // Separate user identification fields and event fields
      var eventKzFields = [];
      kzFields.forEach(field => {
        if (!field.unique) {
          return;
        }

        // user identification checkboxes should not be shown
        if (field.user_field) {
          $scope.entryUniquenessFieldShown[field._id] = false;
        } else {
          eventKzFields.push(field);
          $scope.entryUniquenessFieldDisabled[field._id] = true;
          $scope.entryUniquenessFieldShown[field._id] = true;
        }
      });

      handleDataFields(eventKzFields);
    }

    function initMappingTable(mapping, entryUniqueness) {
      if (!_.isUndefined(mapping) && !_.isEmpty(mapping)) {
        var internalMapping = {};
        if (ctrl.stateOptions && ctrl.stateOptions.locked.mapping !== 'none') {
          _.forEach(ctrl.kzFields, function(field) {
            if (!field._id) {
              return;
            }
            ctrl.isLocked[field._id] = true;
          });
        }

        _.forEach(mapping, function(m) {
          // retro-compatibility, before we were using column instead of value
          if (m.column) {
            m.value = m.column;
            delete m.column;
          }
          internalMapping[m.fieldId] = m;
          var fieldDef = ctrl.kzFields.find(item => item._id === m.fieldId);
          if (fieldDef) {
            if (m.type === 'constant') {
              constructForm(fieldDef);
              $scope.formsModels[m.fieldId] = { [m.fieldId]: m.value };
            }
          }
        });
        $scope.internalMapping = internalMapping;
      } else {
        ctrl.autoMapAll();
      }

      handleEntryUniquenessChanges($scope.internalMapping, ctrl.kzFields, entryUniqueness);
    }

    // Build the table with sections for user identification and data fields
    function buildEventMappingKzFields(kzFields) {
      var kzFieldsWithSections = [
        {
          title: 'User identification',
          fields: _.filter(kzFields, function(f) { return f.user_field; })
        },
        {
          title: 'Data',
          fields: _.filter(kzFields, function(f) { return !f.user_field; })
        }
      ];
      ctrl.kzFields = [];
      _.forEach(kzFieldsWithSections, function(s) {
        ctrl.kzFields.push({ title: s.title });
        _.forEach(s.fields, function(f) {
          ctrl.kzFields.push(f);
        });
      });
    }
    function exportMapping(mapping) {
      ctrl.mapping = _.chain(mapping)
                      .map(function(mapObj) {
                        if (!mapObj.type) {
                          mapObj = {
                            // fieldId: _id,
                            type: 'column',
                            value: mapObj.value
                          };
                        }
                        return mapObj;
                      })
                      .filter(function(columnMap) {
                        return !_.isUndefined(columnMap.value);
                      })
                      .value();
    }

    this.$onInit = function() {
      initAvailableMappingOptions();
      initMappingTable(ctrl.mapping, ctrl.entryUniqueness);
    };

    this.$onChanges = function(changes) {
      // When changing the docType we need to rebuilt the table with the sections
      if (changes.kzFields) {
        var currentKzFields = _.map(changes.kzFields.currentValue, function(field) {
          return field._id;
        });
        var previousKzFields = _.map(changes.kzFields.previousValue, function(field) {
          return field._id;
        });
        if (!_.isEqual(currentKzFields, previousKzFields)) {
          $scope.internalMapping = {};
        }

        var newKzFields = changes.kzFields.currentValue;
        if (newKzFields && ctrl.docType === 'event') {
          buildEventMappingKzFields(newKzFields);

          if (_.isUndefined(ctrl.mapping) || _.isEmpty(ctrl.mapping)) {
            ctrl.autoMapAll();
          }
        }
      }

      // When changing the file, the columns are updated as well
      if (changes.fileColumns) {
        var currentFileColumns = changes.fileColumns.currentValue;
        var previousFileColumns = changes.fileColumns.previousValue;
        if (!_.isEqual(currentFileColumns, previousFileColumns)) {
          initAvailableMappingOptions();
          initMappingTable(ctrl.mapping);
        }
      }
      exportMapping($scope.internalMapping);
    };

    $scope.$watch('formsModels', function(newFormModel) {
      if (newFormModel) {
        Object.keys(newFormModel).forEach(function(fieldId) {
          if (newFormModel[fieldId] && newFormModel[fieldId][fieldId] !== undefined) {
            $scope.internalMapping[fieldId].value = newFormModel[fieldId][fieldId];
          }
        });
      }
    }, true);

    $scope.$watch('internalMapping', function(newMapping, oldMapping) {
      if (!_.isEqual(newMapping, oldMapping)) {
        handleEntryUniquenessChanges(newMapping, ctrl.kzFields);
      }
      exportMapping(newMapping);
    }, true);

    $scope.$watchCollection('entryUniquenessSelectedFields', function(
      newEntryUniqueness,
      oldEntryUniqueness
    ) {
      // This is handling the case when importing user, one only unique field can be selected
      if (ctrl.docType === 'user') {
        // Find the field that was newly selected
        var newlySelectedField = null;
        var newSelectedFields = _.filter(_.keys(newEntryUniqueness), function(fieldId) {
          return newEntryUniqueness[fieldId] === true;
        });

        for (var i = 0; i < newSelectedFields.length; i++) {
          var fieldId = newSelectedFields[i];
          if (!oldEntryUniqueness[fieldId]) {
            newlySelectedField = fieldId;
            break;
          }
        }

        // Unselect all fields except the newly selected one
        if (newlySelectedField) {
          _.forEach(newSelectedFields, function(fieldId) {
            if (fieldId !== newlySelectedField) {
              newEntryUniqueness[fieldId] = false;
            }
          });
        }
      }

      ctrl.entryUniqueness = _.filter(_.keys(newEntryUniqueness), function(fieldId) {
        return newEntryUniqueness[fieldId] === true;
      });
    });

    ctrl.unlock = function(_id, fieldDefinition) {
      ctrl.isLocked[_id] = false;
      handleEntryUniquenessChanges($scope.internalMapping, ctrl.kzFields);

      constructForm(fieldDefinition);
    };
  }

  ColumnsMappingController.$inject = ['$scope', 'FormsService', 'ALL_TYPES'];

  angular.module('component.bulkActions')
    .component('columnsMapping', {
      templateUrl: 'app/components/bulk-actions/import-csv/components/columns-mapping.html',
      controller: ColumnsMappingController,
      bindings: {
        fileColumns: '<',
        stateOptions: '=',
        kzFields: '<',
        entryUniquenessKzFields: '<',
        mapping: '=',
        entryUniqueness: '=',
        docType: '=',
        action: '='
      }
    });
})();
