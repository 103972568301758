 import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { CommonModule, NgSwitch } from "@angular/common";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { MFAService } from "@fry/system/account/mfa/mfa.service";
import * as QRCode from 'qrcode';
import { MFAMethod, MFAMethodsEnum } from "@fry/system/account/mfa/mfa";


@Component({
  selector: 'app-mfa-setup',
  templateUrl: './create-mfa-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgSwitch,
    MatFormField,
    MatInput,
    MatDialogTitle,
    MatDialogContent,
    MatLabel,
    MatDialogActions,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CreateMfaDialogComponent {
  @Output() completed = new EventEmitter<any>();

  protected readonly MFAMethodsEnum = MFAMethodsEnum;

  credentialUsername: string = '';
  availableMethods: MFAMethod[] = [];
  isPreselectedMethod: boolean = false;
  currentStep: 'selectMethod' | 'completeRegistration' = 'selectMethod';
  selectedMethod: MFAMethodsEnum | null = null;

  errorMessage: string = '';
  mfaId: string | null = null;

  // Email OTP variables
  otpForm: FormGroup;

  // TOTP variables
  totpForm: FormGroup;
  qrCodeDataUrl: string | null = null;
  secretKey: string | null = null;


  constructor(
    private mfaService: MFAService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      credentialUsername: string,
      availableMethods: MFAMethod[],
      preSelectMethod?: MFAMethodsEnum
    }
  ) {
    this.credentialUsername = data.credentialUsername;
    this.availableMethods = data.availableMethods;
    if(data.preSelectMethod) {
      this.selectMethod(data.preSelectMethod);
      this.isPreselectedMethod = true;
    }

    this.otpForm = this.fb.group({
      code: ['', Validators.required],
    });

    this.totpForm = this.fb.group({
      code: ['', Validators.required],
    });
  }

  selectMethod(method: MFAMethodsEnum.TOTP | MFAMethodsEnum.EMAIL_OTP) {
    this.selectedMethod = method;
    this.currentStep = 'completeRegistration';
    this.initRegistration(method);
  }

  // Initiate Registration
  async initRegistration(method: MFAMethodsEnum.TOTP | MFAMethodsEnum.EMAIL_OTP) {
    let response;
    if (method === MFAMethodsEnum.EMAIL_OTP) {
      response = await this.mfaService.initEmailOTPRegistration(this.credentialUsername);
    } else if (method === MFAMethodsEnum.TOTP) {
      response = await this.mfaService.initTOTPRegistration(this.credentialUsername);
    }

    if (response.state !== 'ok') {
      this.errorMessage = `Failed, reason: ${response.error}.`;
      return;
    }

    this.mfaId = response.data.mfa_id;
    if (method === MFAMethodsEnum.TOTP) {
      this.secretKey = response.data.uri.match(/secret=([^&]+)/)[1];
      this.qrCodeDataUrl = await QRCode.toDataURL(response.data.uri);
    }
  }

  // Complete Registration (Unified for both TOTP and Email OTP)
  async completeRegistration() {
    const code = this.selectedMethod === MFAMethodsEnum.EMAIL_OTP ? this.otpForm.value.code : this.totpForm.value.code;

    let response;
    if (this.selectedMethod === MFAMethodsEnum.EMAIL_OTP) {
      response = await this.mfaService.completeEmailOTPRegistration(this.credentialUsername, this.mfaId!, code);
    } else if (this.selectedMethod === MFAMethodsEnum.TOTP) {
      response = await this.mfaService.completeTOTPRegistration(this.credentialUsername, this.mfaId!, code);
    }

    if (response?.state === 'ok') {
      this.completed.emit({ method: this.selectedMethod, status: 'success' });
    } else {
      this.errorMessage = `Failed: ${response.error}`;
    }
  }

  // Determine if the Complete Registration button should be disabled
  isCompleteRegistrationDisabled(): boolean {
    return this.selectedMethod === MFAMethodsEnum.EMAIL_OTP
      ? !this.otpForm.valid
      : this.selectedMethod === MFAMethodsEnum.TOTP
      ? !this.totpForm.valid
      : true;
  }

  // Utility function for copying TOTP secret key
  async copyToClipboard() {
    if (this.secretKey) {
      try {
        await navigator.clipboard.writeText(this.secretKey);
      } catch (err) {
        console.error('Failed to copy to clipboard:', err);
      }
    }
  }

  // Go back to the previous step
  goBack() {
    this.currentStep = 'selectMethod';
    this.selectedMethod = null;
    this.errorMessage = '';
    this.qrCodeDataUrl = null;
    this.secretKey = null;
    this.otpForm.reset();
    this.totpForm.reset();
  }

  closeDialog() {
    this.completed.emit({ method: null, status: 'cancelled' });
  }

}
