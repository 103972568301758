<section class="wrapper">
  <header class="row">
    <div class="col-xs-9">
      <h1>Two Factor Authentications</h1>
      <p class="text-gray">Username: <span class="text-success">{{credentialUsername()}}</span></p>
    </div>
    <div class="col-xs-3 text-right">
      <button class="btn border border-primary text-primary" (click)="goBack()">
        <i class="icon-left-open-big"></i>
        Back
      </button>
    </div>
  </header>

  <div class="row">
    <div class="col-xs-12">
      <p class="box box-warning">
        Your organisation has enforced two factor authentication on your account. You cannot turn it off, but you can add or change your second steps.
      </p>

      <div class="row row-bordered" *ngFor="let mfa of mfas;">
        <div class="col-xs-8">
          <p>{{ readableName[mfa.type] }}</p>
          <div class="text-gray">Registered on {{ mfa.createdAt | date:'medium' }}</div>
        </div>
        <div class="col-xs-4 text-right">
          <ul class="list-inline list-unstyled">
            <li><button class="btn btn-sm btn-primary" (click)="createMFA(mfa.type)" *ngIf="canReplaceMFA(mfa)" attr.aria-label="Replace {{ readableName[mfa.type] }}">Replace</button></li>
            <li><button class="btn btn-sm btn-danger" (click)="deleteMFA(mfa.id)" *ngIf="canDelete()" attr.aria-label="Remove {{ readableName[mfa.type] }}">Remove</button></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn-primary add-credential" (click)="createMFA()" *ngIf="canAdd()">
        <i class="icon-plus"></i>
        Setup two factor authentication
      </button>
    </div>
  </div>

</section>
